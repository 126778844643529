<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/admin/home">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label>{{ $t("WorkGroup") }}</label>
      <span>/</span>
      <label active>{{ $t("FailedCreditCards") }}</label>
    </div>

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>
  
    </v-toolbar>

    <!-- Start Filters -->


    <div>
      <v-row>
        <v-col :md="3">
          <div>
            <label>{{ $t("Search") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name"
              hide-details
              dense
              outlined
              v-model="filters.search"
            ></v-text-field>
          </div>
        </v-col>

        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.status="{ item }">
        <p
          @click="updateStatus(item)"
          v-if="item.status"
          class="my-0 clr-success clickable"
        >
          {{ $t("Active") }}
        </p>
        <p @click="updateStatus(item)" v-else class="my-0 clr-danger clickable">
          {{ $t("InActive") }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        
         <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="goDetails(item.failed_credit_cards)"
          style="color: purple !important; cursor: pointer; font-size: 22px"
          >mdi-eye</v-icon
        >


      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->


    
    <v-dialog v-model="dialog" :width="config.modalFormWidth" @click:outside="dialog=false">
    <v-card>
      <v-card-title>
        <span class="text-h6">{{$t('Failed credit cards')}}</span>
      </v-card-title>

      <v-card-text>
        <table class="custom-table">
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('CustomerID') }}</th>
              <th>{{$t('CardNumber')}}</th>
              <th>{{ $t('FailedCount') }}</th>
              <th>{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="card in failed_credit_cards" :key="card.id">
              <td>{{ card.id }}</td>
              <td>{{ card.customer_id }}</td>
              <td>{{ card.card_number }}</td>
              <td>{{ card.failed_count }}</td>
              <td>
                <span @click="activate(card.id)"  v-if="card.failed_count != 0 && !card.is_activated" class="activateAction">
                  {{ $t('Activate') }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>

    </v-card>
  </v-dialog>

  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "customers",
  data() {
    return {
      failed_credit_cards: [],
      is_edit: false,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "FailedCreditCardsTable",
        crudHeader: "FailedCreditCards",
        crudApi: "failedCreditCards",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("Name"),
            value: "name",
            width: "10rem",
          },
          {
            text: this.$t("Mobile"),
            value: "mobile",
            width: "10rem",
          },
          {
            text: this.$t("Mobile") + ' 2',
            value: "phone_number",
            width: "10rem",
          },
          {
            text: this.$t("Email"),
            value: "email",
            width: "10rem",
          },
        ],
      },
      filters: {
        search: null,
      },
      formData: {
        id: 0,
        name: null,
        mobile: null,
        email: null,
        password:null,
        confrim_password:null,
        city_id: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      passwordDialog: false,
    };
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    goDetails(items) {
      this.failed_credit_cards= items;
this.dialog= true;
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    clearData() {
      this.is_edit= false;
      this.formData = {
        name: null,
        mobile: null,
        email: null,
        password:null,
        confrim_password:null,
        city_id: null,
      };
      this.$validator.reset();
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    activate(id) {
      let data = {
        id: id
      }
      this.$store.dispatch(`${this.config.crudApi}/activate`,data)
        .then((res) => {
          this.customNotifySuccess(this.$t('Activate'), this.$t('ActivatedSuccessfully'));
          this.getDataFromApi();
          // Find and update the activated card's status to hide the button
          const card = this.failed_credit_cards.find(card => card.id === id);
          if (card) {
            card.is_activated = true; // Update the card status
            card.failed_count= 0;
          }
        })
        .finally(() => {
        });
    },
    getDataFromApi(options) {
      this.table.loading = true;

      let sendData = {...this.filters,...options};
      this.$store.dispatch(`${this.config.crudApi}/getData`,sendData)
        .then((res) => {
          this.table.items = res.data.data;
          console.log(res.data)
          this.table.length = Math.ceil(res.data.total / res.data.per_page);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();

    // if (
    //   (this.user &&
    //     this.user.permissions &&
    //     this.user.permissions.includes("update_customer")) ||
    //   (this.user &&
    //     this.user.permissions &&
    //     this.user.permissions.includes("delete_customer"))
    // )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
        width: "8rem",
      });
  },
};
</script>

<style>

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.custom-table th {
  background-color: #f4f4f4;
  color: #333;
}
.activateAction {
  font-size: 14px;
  color:green;font-weight: bold;cursor: pointer;
}
.activateAction:hover {
  color: green;
}
</style>